<template>
  <modal
    :show="isShow"
    :click-to-close="false"
    size="sm"
    class="modal-add-address"
    @hide="$emit('hide')"
  >
    <div slot="header">
      <h2 class="font-normal">{{ name }} contact information</h2>
    </div>
    <div slot="body">
      <address-form
        :key="`address-form_${loading}`"
        :value="model"
        :consignment="value"
        :name="name"
        :loading="loading"
        :organizations-list="organizationsList"
        :loading-organizations-list="loadingOrganizationsList"
        @update:model="model = $event"
      />
    </div>
    <div slot="footer" class="flex w-full">
      <button
        type="button"
        class="ml-auto mr-4 text-sm text-red-500"
        @click="detailModal = false"
      >
        Cancel
      </button>
      <button type="button" class="btn" @click="save">Edit</button>
    </div>
  </modal>
</template>
<script>
import axios from "axios";
import { requestErrors } from "@/utils/errors";

import AddressForm from "~/components/AddressForm.vue";

const promises = [];

export default {
  name: "DetailShipmentAddressModal",
  components: {
    AddressForm,
  },
  props: {
    isShow: Boolean,
    value: {
      type: Object,
      default: () => ({}),
    },
    name: String,
  },
  watch: {
    async isShow(value) {
      if (!value) {
        return;
      }

      if (!this.organizationsList.length) {
        promises.push(this.fetchOrganizations());
      }

      if (!this.countries.length) {
        promises.push(this.$store.dispatch("countries/init"));
      }

      await Promise.all(promises);

      await this.updateConsignmentData();
    },
  },
  computed: {
    key() {
      return this.addressModalKeyNameDictionary[this.name];
    },
    countries() {
      return this.$store.getters["countries/list"];
    },
  },
  data() {
    return {
      loadingOrganizationsList: false,
      organizationsList: [],
      model: {},
      loading: false,
    };
  },
  methods: {
    async fetchOrganizations() {
      this.loadingOrganizationsList = true;

      try {
        const { data } = await axios.get(
          `${this.$apiUrl.organizations.base}?pageSize=1000`
        );
        this.organizationsList = data.data;
      } catch (error) {
        this.$snotify.error(requestErrors(error));
      }

      this.loadingOrganizationsList = false;
    },
    async createContact(payload) {
      try {
        const {
          data: { data },
        } = await axios.post(this.$apiUrl.addressBook.contact, {
          organization_id: this.model.organization.id,
          ...payload,
        });

        return data.id;
      } catch (error) {
        this.$snotify.error(requestErrors(error));
      }
    },
    async getSavePayload() {
      let contact_id = this.model.contact.id;

      if (this.model.create_contact) {
        contact_id =
          (await this.createContact(this.model.create_contact)) || contact_id;
      }

      return {
        organization_id: this.model.organization.id,
        address: this.model.address,
        address2: this.model.address2,
        country_code: this.model.country.code,
        city: this.model.city.name,
        postcode: this.model.postcode,
        name: this.model.name,
        types: this.model.types?.map((item) => item.value),
        contact_id,
        delivery_instruction: this.model.delivery_instruction,
        delivery_instruction_id: this.model.delivery_instruction_id,
        pickup_instruction: this.model.pickup_instruction,
        pickup_instruction_id: this.model.pickup_instruction_id,
      };
    },
    async save() {
      if (
        !this.model.organization ||
        !this.model.types.length ||
        !this.model.address ||
        !this.model.country ||
        !this.model.city ||
        !this.model.postcode ||
        !this.model.name
      ) {
        this.$snotify.error("Fill in required fields.");

        return;
      }

      this.loading = true;

      try {
        await axios.post(
          this.$apiUrl.addressBook.base + "/" + this.model.id,
          await this.getSavePayload()
        );
      } catch (error) {
        this.$snotify.error(requestErrors(error));
      }

      this.loading = false;
      this.$snotify.success("Address has been updated");

      this.$emit("updated");
      this.$emit("hide");
    },
    async updateConsignmentData() {
      this.loading = true;

      try {
        this.consignmentData = this.value;

        if (!this.value[`${this.key}_full_address`]) {
          this.model = {};

          return;
        }

        const response = await axios.get(
          this.$apiUrl.addressBook.base +
            "/" +
            this.value[`${this.key}_full_address`].id
        );

        this.model = response.data.data;
        this.model.types = this.companyTypeDictionary.filter((item) => {
          return this.model.types.find((type) => type === item.value);
        });

        this.model.organization = this.organizationsList.find(
          (organization) => organization.id === this.model.organization_id
        );

        this.model.create_contact = null;
        this.model.contact = this.value[`${this.key}_full_address`].contact;
        this.model.city = this.value[`${this.key}_full_address`].city;
        this.model.country = this.countries.find(
          (country) => country.code === this.model.city.country_code
        );
      } catch (error) {
        this.$snotify.error(requestErrors(error));
      }

      this.loading = false;
    },
  },
};
</script>
