<template>
  <div class="relative" style="min-height: 500px;">
    <spinner-block v-if="loading" />

    <div v-else class="flex flex-wrap">
      <modal-field
        label="Organization"
        required
        :class="'mb-4 w-full'"
        :key="`${componentCounter}_organization`"
      >
        <multiselect
          :value="modelValue.organization"
          class="mt-1"
          :options="organizationsList"
          disabled
          track-by="id"
          label="name"
        />
      </modal-field>
      <modal-field :label="'Company name'" required :class="'mb-4 w-full'">
        <input
          type="text"
          class="mt-1"
          v-model="modelValue.name"
          required
          autocomplete="none"
        />
      </modal-field>

      <modal-field label="Address ID" class="mb-4 w-full">
        <input
          type="text"
          class="mt-1"
          v-model="modelValue.unifaun_address_id"
          autocomplete="none"
        />
      </modal-field>

      <modal-field :label="'Address line 1'" required :class="'mb-4 w-full'">
        <input
          type="text"
          class="mt-1"
          v-model="modelValue.address"
          required
          autocomplete="none"
        />
      </modal-field>

      <modal-field :label="'Address line 2'" :class="'mb-4 w-full'">
        <input
          type="text"
          class="mt-1"
          v-model="modelValue.address2"
          autocomplete="none"
        />
      </modal-field>

      <modal-field :label="'Zip code'" required :class="'w-1/3 mb-4 pr-4'">
        <input
          type="text"
          class="mt-1"
          v-model="modelValue.postcode"
          required
          autocomplete="none"
        />
      </modal-field>

      <modal-field :label="'City'" required :class="'w-2/3 mb-4'">
        <input
          v-if="!modelValue.city"
          type="text"
          class="mt-1"
          required
          disabled
          autocomplete="none"
        />

        <input
          v-else
          type="text"
          class="mt-1"
          v-model="modelValue.city.name"
          required
          autocomplete="none"
        />
      </modal-field>

      <modal-field :label="'State'" :class="'w-2/4 pr-4 mb-4'">
        <input
          type="text"
          class="mt-1"
          v-model="modelValue.state"
          autocomplete="none"
        />
      </modal-field>

      <modal-field
        :label="'Country'"
        required
        :class="'w-2/4 mb-4'"
        :key="`${componentCounter}_country`"
      >
        <multiselect
          v-model="modelValue.country"
          class="mt-1"
          :options="countries"
          track-by="id"
          label="name"
          @select="componentCounter++"
        >
        </multiselect>
      </modal-field>

      <modal-field :label="'Contact type'" required :class="'w-full mb-4'">
        <multiselect
          v-model="modelValue.types"
          :options="companyTypeDictionary"
          class="mt-1"
          track-by="value"
          label="name"
          multiple
          open-direction="top"
        >
        </multiselect>
      </modal-field>

      <modal-field
        label="Default contact"
        :class="'w-full mb-4'"
        :bodyClass="'flex flex__align-center'"
      >
        <div class="w-full">
          <autocomplete
            v-if="modelValue.contact"
            v-model="modelValue.contact.name"
            :endpoint="contactSearchEndpoint"
            :params="{
              organization_id: modelValue.organization
                ? modelValue.organization.id
                : '',
            }"
            required
            direction="top"
            :body-class="['mt-1']"
            :disabled="Boolean(this.modelValue.create_contact)"
            @click="updateContactPerson"
          >
            <template v-slot="slotProps">
              <span v-if="slotProps.data.default" class="mr-2 text-gray-600"
                >(default)</span
              >
              {{ slotProps.data.name }}
              <span class="mr-2 text-gray-600" v-text="slotProps.data.email" />
            </template>
          </autocomplete>
        </div>

        <button
          type="button"
          class="flex self-center ml-2 text-2xl cursor-pointer"
          :key="`create_contact_${modelValue.create_contact}`"
          @click="toggleCreateContact"
        >
          <svg-importer icon-name="icons/add-user" key="contacts_book" />
        </button>
      </modal-field>

      <div v-if="modelValue.create_contact" class="w-full">
        <modal-field label="Full name" required class="mb-4 w-full">
          <input
            type="text"
            class="mt-1"
            v-model="modelValue.create_contact.name"
            required
            autocomplete="none"
          />
        </modal-field>

        <modal-field label="Phone" required class="mb-4 w-full">
          <the-mask
            mask="+#############"
            class="mt-1"
            v-model="modelValue.create_contact.phone"
            required
          />
        </modal-field>

        <modal-field label="E-mail" class="mb-4 w-full">
          <input
            type="text"
            class="mt-1"
            v-model="modelValue.create_contact.email"
            autocomplete="none"
          />
        </modal-field>
      </div>

      <modal-field label="Delivery instruction" class="mb-4 w-full">
        <autocomplete
          v-model="modelValue.delivery_instruction"
          :endpoint="getInstructionsEndpoint"
          :filters="getInstructionsFilter('delivery')"
          :maxlength="getMaxLength('delivery_instruction')"
          direction="top"
          @input="updateInstructionValue('delivery_instruction', $event)"
          @click="setInstructionValue('delivery_instruction', $event)"
        >
          <template v-slot="slotProps">
            {{ slotProps.data.instruction }}
          </template>
        </autocomplete>
      </modal-field>

      <modal-field label="Pickup instruction" class="mb-4 w-full">
        <autocomplete
          v-model="modelValue.pickup_instruction"
          :endpoint="getInstructionsEndpoint"
          :filters="getInstructionsFilter('pickup')"
          :maxlength="getMaxLength('pickup_instruction')"
          direction="top"
          @input="updateInstructionValue('pickup_instruction', $event)"
          @click="setInstructionValue('pickup_instruction', $event)"
        >
          <template v-slot="slotProps">
            {{ slotProps.data.instruction }}
          </template>
        </autocomplete>
      </modal-field>
    </div>
  </div>
</template>
<script>
import SpinnerBlock from "~/components/SpinnerBlock.vue";
import Autocomplete from "~/components/Autocomplete.vue";

const DEFAULT_CONTACT = {
  id: null,
  name: "",
  email: "",
  phone: "",
};

export default {
  name: "AddressForm",
  components: {
    SpinnerBlock,
    Autocomplete,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
    },
    organizationsList: {
      type: Array,
      default: () => [],
    },
    loadingOrganizationsList: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      componentCounter: 0,
      consignmentData: null,
    };
  },
  computed: {
    isGodUser() {
      return this.$store.getters["auth/isGodUser"];
    },
    countries() {
      return this.$store.getters["countries/list"];
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    contactSearchEndpoint(val) {
      return `${this.$apiUrl.addressBook.contact}/search?name=${val}`;
    },
    getInstructionsEndpoint(val) {
      if (!val) {
        return `${this.$apiUrl.consignments.bookingInstructions}?`;
      }

      return `${this.$apiUrl.consignments.bookingInstructions}?filter[instruction]=${val}`;
    },
    getMaxLength(key) {
      if (key === "pickup_instruction") {
        return "96";
      }

      if (key === "delivery_instruction") {
        return "90";
      }

      return "";
    },
    getInstructionsFilter(key) {
      return {
        organization_id: this.modelValue.organization_id,
        type: key,
      };
    },
    updateInstructionValue(key, event) {
      this.modelValue[key] = event;
      this.modelValue[`${key}_id`] = null;
    },
    setInstructionValue(key, value) {
      this.modelValue[key] = value.instruction;
      this.modelValue[`${key}_id`] = value.id;
    },
    async toggleCreateContact() {
      this.modelValue.create_contact = this.modelValue.create_contact
        ? null
        : DEFAULT_CONTACT;

      this.componentCounter++;

      if (this.modelValue.create_contact) {
        this.$nextTick(() => {
          const modalBodyElement = this.$el.parentElement;
          modalBodyElement.scrollTop = modalBodyElement.scrollHeight;
        });
      }
    },
    updateContactPerson(value) {
      this.modelValue.contact = value;
    },
  },
};
</script>